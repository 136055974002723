import { Box, Stat, StatLabel, StatNumber } from "@chakra-ui/react"
import { CompanyStatisticsData } from "src/types/CompanyDataTypes"
import React from "react"

type CompanyStatisticsProps = {
  companyStatistics?: CompanyStatisticsData
}

function CompanyStatistics(props: CompanyStatisticsProps) {
  return (
    <Box flexDirection="row" display="flex">
      <Stat>
        <StatLabel>Gjennomsnittrating</StatLabel>
        <StatNumber>{props.companyStatistics?.averageRating}</StatNumber>
      </Stat>
      <Stat>
        <StatLabel>Antall tilbakemeldinger</StatLabel>
        <StatNumber>{props.companyStatistics?.totalRatings}</StatNumber>
      </Stat>
      <Stat>
        <StatLabel>Prosentvis antall turer under makspris</StatLabel>
        <StatNumber>
          {props.companyStatistics?.percentageBelowMaxPrice}
        </StatNumber>
      </Stat>
    </Box>
  )
}

export default CompanyStatistics
