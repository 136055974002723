import React, { useEffect, useMemo, useState } from "react"
import {
  useActiveTrips,
  useGetLatestAdminMessageForActiveErrorTrips,
} from "src/api/dashboard"
import { withErrorBoundry } from "src/api/withErrorBoundry"
import { TripIdLink } from "src/components/links/TripIdLink"
import { TripStatus } from "src/components/tripComponents/TripStatus"
import { ActiveTrip } from "src/types/DeveloperTypes"
import { formatDatetime } from "src/utils/date"
import {
  Switch,
  Box,
  Table,
  Thead,
  Tbody,
  Td,
  Th,
  Tr,
  HStack,
  Heading,
  Text,
} from "@vygruppen/spor-react"
import { TaxiAdminCard } from "../components/layout/TaxiAdminCard"
import { LatestAdminMessageForTripIdResponse } from "../utils/taxi"

function ErrorTrips() {
  const allActiveTrips = useActiveTrips()
  const latestAdminMessagesForTripsWithError =
    useGetLatestAdminMessageForActiveErrorTrips()

  const [switchValue, setSwitchValue] = useState<boolean>(true)
  const [latestAdminMessages, setLatestAdminMessages] = useState<
    LatestAdminMessageForTripIdResponse[]
  >([])

  const filteredTrips = switchValue
    ? allActiveTrips?.tripsWithError
    : allActiveTrips?.customerServiceTrips

  const trips = useMemo(() => filteredTrips, [filteredTrips])

  useEffect(() => {
    if (Array.isArray(latestAdminMessagesForTripsWithError)) {
      setLatestAdminMessages(latestAdminMessagesForTripsWithError)
    } else {
      setLatestAdminMessages([])
    }
  }, [latestAdminMessagesForTripsWithError])

  return (
    <TaxiAdminCard>
      <Heading as="h2" marginBottom={3}>
        Turer med feil ({trips?.length})
      </Heading>
      <HStack alignItems="center" display="flex">
        <Switch
          onChange={() => {
            setSwitchValue(!switchValue)
          }}
          defaultChecked={switchValue}
          size="sm"
        />
        <Text variant="md">Vis alle feilturer</Text>
      </HStack>
      {trips && (
        <Box flex="1" textAlign="left" mt="6">
          <ErrorTripTable
            trips={trips}
            displayingAllTrips={switchValue}
            latestErrorMessages={latestAdminMessages}
          />
        </Box>
      )}
    </TaxiAdminCard>
  )
}

type ErrorTripTableProps = {
  trips: ActiveTrip[]
  displayingAllTrips: boolean
  latestErrorMessages: LatestAdminMessageForTripIdResponse[]
}

function ErrorTripTable(props: ErrorTripTableProps) {
  const getMessagesForTrip = (tripId: string): string => {
    const messagesForTrip = props.latestErrorMessages.find(
      (message) => message.tripId === tripId,
    )

    return messagesForTrip?.latestAdminMessage?.messageBody ?? "Ingen kommentar"
  }

  return (
    <Table size="sm" variant="simple" colorScheme="grey">
      <Thead>
        <Tr>
          <Th>Ønsket avreise</Th>
          <Th>BookingNummer</Th>
          <Th>Selskap</Th>
          <Th>Status</Th>
          <Th>Teknologileverandør</Th>
          <Th>Siste kommentar</Th>
        </Tr>
      </Thead>
      <Tbody>
        {props.trips.map((item) => (
          <Tr key={item.tripId}>
            <Td>{formatDatetime(item.requestedPickUpTime)}</Td>
            <Td>
              <TripIdLink
                tripId={item.tripId}
                bookingNumber={item.bookingNumber}
              />
            </Td>
            <Td>{item.companyName}</Td>
            <Td>
              <TripStatus
                status={item.status}
                errorCorrectionTripStatus={item.errorCorrectionTripStatus ?? ""}
              />
            </Td>
            <Td>{item.technologyProvider}</Td>
            <Td>{getMessagesForTrip(item.tripId)}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}

export default withErrorBoundry(ErrorTrips)
