import React, { Suspense } from "react"
import { Spinner } from "@chakra-ui/react"

const Loading = () => <Spinner />

export function withErrorBoundry<T>(
  WrappedComponent: React.ComponentType<T>,
  FallbackComponent: React.ComponentType = Loading,
): React.FunctionComponent<T> {
  return (props) => (
    <ErrorBoundary>
      <Suspense fallback={<FallbackComponent />}>
        <WrappedComponent {...props} />
      </Suspense>
    </ErrorBoundary>
  )
}

type ErrorBoundryState = { hasError: boolean; error: null | Error }

class ErrorBoundary extends React.Component {
  state: ErrorBoundryState = {
    hasError: false,
    error: null,
  }

  static getDerivedStateFromError(error: Error) {
    return {
      hasError: true,
      error,
    }
  }

  render() {
    if (this.state.hasError) {
      return <h1>Failed to fetch data: {this.state.error?.message}</h1>
    }
    return this.props.children
  }
}
