import { Link, useNavigate } from "react-router-dom"
import VyLogo from "src/assets/VyLogo"
import { useAuth0 } from "src/auth/useAuth-compat"
import { HeaderLinkButton } from "src/components/links/HeaderLinkButton"
import React from "react"
import {
  Button,
  InfoSelect,
  Item,
  ItemLabel,
  Box,
  Stack,
  Text,
} from "@vygruppen/spor-react"
import CompaniesDropdownMenuCopy from "./CompaniesDropdownMenu"

function Header() {
  const { logout } = useAuth0()
  const navigate = useNavigate()

  const menuOptions = [
    { key: 0, path: "/", title: "Hjem" },
    { key: 1, path: "/developer/unfinished-tasks", title: "Utviklerside" },
    { key: 2, path: "/trips/error", title: "Turer med feil" },
    { key: 3, path: "/trips/ongoing", title: "Pågående turer" },
    { key: 4, path: "/trips/prebooked", title: "Forhåndsbestilte turer" },
    { key: 5, path: "/company-status", title: "Selskapsstatus" },
    { key: 6, path: "/metabase", title: "Metabase" },
  ]

  const navigateTo = (key: string | number) => {
    if (typeof key === "number" && menuOptions[key]) {
      navigate(menuOptions[key].path)
    }
  }

  return (
    <Box
      alignItems="center"
      display="flex"
      margin="6"
      marginTop="3"
      justifyContent="space-between"
    >
      <Link to="/">
        <Box alignItems="center" display="flex">
          <VyLogo />
          <Text fontSize="md" paddingLeft="30">
            Taxi Admin
          </Text>
        </Box>
      </Link>
      <Stack flexDirection="row" spacing={4} align="center">
        <HeaderLinkButton to="/trips/error">Turer med feil</HeaderLinkButton>
        <HeaderLinkButton to="/">Finn tur</HeaderLinkButton>
        <InfoSelect
          label="Meny"
          placeholder="Meny"
          items={menuOptions}
          onChange={(key) => navigateTo(key)}
        >
          {(item) => (
            <Item textValue={item.title}>
              <ItemLabel>{item.title}</ItemLabel>
            </Item>
          )}
        </InfoSelect>
        <CompaniesDropdownMenuCopy />
        <Button
          variant="tertiary"
          onClick={() => logout({ returnTo: window.location.origin })}
        >
          Logg ut
        </Button>
      </Stack>
    </Box>
  )
}
export default Header
