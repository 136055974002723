import {
  Box,
  Heading,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@vygruppen/spor-react"
import { ActiveTrip } from "src/types/DeveloperTypes"
import { TripIdLink } from "src/components/links/TripIdLink"
import { useActiveTrips } from "src/api/dashboard"
import { withErrorBoundry } from "src/api/withErrorBoundry"
import { TaxiAdminCard } from "src/components/layout/TaxiAdminCard"
import React from "react"

function PreBookedTrips() {
  const activeTrips = useActiveTrips()

  return (
    <TaxiAdminCard>
      <Heading as="h2">
        {activeTrips?.preBookedTrips.length} forhåndsbestilte tur(er)
      </Heading>
      {activeTrips && (
        <Box flex="1" textAlign="left" mt="6">
          <PrebookedTripTable trips={activeTrips.preBookedTrips} />
        </Box>
      )}
    </TaxiAdminCard>
  )
}

type PrebookedTripTableProps = {
  trips: ActiveTrip[]
}

function PrebookedTripTable(props: PrebookedTripTableProps) {
  return (
    <Table size="sm" variant="simple">
      <Thead>
        <Tr>
          <Th>Ønsket avreise</Th>
          <Th>TripId</Th>
          <Th>Selskap</Th>
          <Th>Status</Th>
          <Th>Teknologileverandør</Th>
        </Tr>
      </Thead>
      <Tbody>
        {props.trips.map((item) => (
          <Tr key={item.tripId}>
            <Td>{item.requestedPickUpTime}</Td>
            <Td>
              <TripIdLink tripId={item.tripId} />
            </Td>
            <Td>{item.companyName}</Td>
            <Td>{item.status}</Td>
            <Td>{item.technologyProvider}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}

export default withErrorBoundry(PreBookedTrips)
