import { StaticCard } from "@vygruppen/spor-react"
import React, { ReactNode } from "react"

type Props = {
  bg?: string
  margin?: string
  padding?: string
  children: ReactNode
}

export function TaxiTripCard({
  children,
  margin = "12",
  padding = "12",
}: Props) {
  return (
    <StaticCard margin={margin} padding={padding} colorScheme="white">
      {children}
    </StaticCard>
  )
}
