import { GridItem } from "@chakra-ui/react"
import React from "react"
import { Stack, Text } from "@vygruppen/spor-react"

type Props = {
  stat: string
  value: string
  hasError?: boolean
}

export function TripInfoStat({ stat, value, hasError }: Props) {
  return (
    <GridItem
      borderRadius="8"
      padding="4"
      border="solid"
      borderColor={hasError ? "#ED0000" : "#E2E8F0"}
      borderWidth="1px"
    >
      <Stack>
        <Text>{stat}</Text>
        <Text variant="md">{value}</Text>
      </Stack>
    </GridItem>
  )
}
