import { useCallback } from "react"
import { useGetAccessToken } from "src/auth/useGetAccessToken"
import useSWR from "swr"

export function useFetcher() {
  const getAccessToken = useGetAccessToken()

  return useCallback(
    async (url: URL, init?: RequestInit) => {
      const accessToken = await getAccessToken()
      if (init === undefined) {
        init = {}
      }
      init.credentials = "include"
      const initWithHeaders = {
        ...init,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json",
          "Content-type": "application/json",
        },
      }

      const response = await fetch(url, initWithHeaders)

      if (response.ok) {
        if (response.status === 204) {
          return {}
        }
        return response.json()
      } else {
        throw new Error(response.status + " " + response.statusText)
      }
    },
    [getAccessToken],
  )
}

export function useSuspenseFetch<T>(url: URL) {
  const fetcher = useFetcher()

  return useSWR<T>(url.toString(), fetcher, { suspense: true })
}

export function usePostRequest<Request, Response>() {
  const fetcher = useFetcher()

  return useCallback(
    async (url: URL, data: Request) => {
      return fetcher(url, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(data),
      }) as Promise<Response>
    },
    [fetcher],
  )
}
