import { taxiBaseURL } from "src/utils/environmentVariables"

const URLJoin = (...args: string[]) =>
  args
    .join("/")
    .replace(/\/+/g, "/")
    .replace(/^(.+):\//, "$1://")
    .replace(/^file:/, "file:/")
    .replace(/\/(\?|&|#[^!])/g, "$1")
    .replace(/\?/g, "&")
    .replace("&", "?")

export function dashboardApiUrl(path: string): URL {
  return new URL(URLJoin(taxiBaseURL(), path))
}
