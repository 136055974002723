import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import React, { useMemo } from "react"
import { useLocation } from "react-router"
import { useSearchForTrips } from "src/api/dashboard"
import { withErrorBoundry } from "src/api/withErrorBoundry"
import { TaxiAdminCard } from "src/components/layout/TaxiAdminCard"
import { TripIdLink } from "src/components/links/TripIdLink"
import { TripStatus } from "src/components/tripComponents/TripStatus"
import { TaxiTrip } from "src/types/TripDataTypes"
import { formatDatetime } from "src/utils/date"

function useQueryParams<T extends Record<string, string>>() {
  const { search } = useLocation()

  return useMemo(() => {
    const params = new URLSearchParams(search)
    const entries = Array.from(params.entries())

    return entries.reduce(
      (acc, [key, value]) => {
        acc[key] = value
        return acc
      },
      {} as Record<string, string>,
    ) as T
  }, [search])
}

function SearchPage() {
  const { userId, vippsOrderId, bookingNumber, dinteroOrderId } =
    useQueryParams<{
      userId?: string
      vippsOrderId?: string
      bookingNumber?: string
      dinteroOrderId?: string
    }>()
  const trips = useSearchForTrips(
    userId,
    vippsOrderId,
    bookingNumber,
    dinteroOrderId,
  )

  return <TaxiAdminCard>{trips && <TripsTable trips={trips} />}</TaxiAdminCard>
}

type TripsTableProps = {
  trips: TaxiTrip[]
}

function TripsTable(props: TripsTableProps) {
  return (
    <Table size="sm">
      <Thead>
        <Tr>
          <Th>Forespurt hentetid</Th>
          <Th>TripId</Th>
          <Th>Status</Th>
          <Th>Selskap</Th>
        </Tr>
      </Thead>
      <Tbody>
        {props.trips.map((item) => (
          <Tr key={item.tripId}>
            <Td>{formatDatetime(item.requestedPickUpTime)}</Td>
            <Td>
              <TripIdLink tripId={item.tripId} />
            </Td>
            <Td>
              <TripStatus status={item.status} errorCorrectionTripStatus="" />
            </Td>
            <Td>{item.companyName}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}

export default withErrorBoundry(SearchPage)
