import React, { useCallback, useState } from "react"
import {
  Heading,
  Table,
  Tr,
  Th,
  Td,
  Tbody,
  Thead,
  Input,
  Button,
  Box,
} from "@chakra-ui/react"
import { dashboardApiUrl } from "src/api/base-url"
import { useGetAdminMessages } from "src/api/dashboard"
import { usePostRequest } from "src/api/useSuspenseFetch"
import { withErrorBoundry } from "src/api/withErrorBoundry"
import { TaxiAdminCard } from "src/components/layout/TaxiAdminCard"
import { formatDatetime } from "src/utils/date"
import { useGetUser } from "src/auth/useGetUser"

type Props = {
  tripId: string
}

function TripAdminMessagesContainer({ tripId }: Props) {
  const { data: { messages = [] } = {}, mutate } = useGetAdminMessages(tripId)
  const [newMessage, setNewMessage] = useState<string>("")
  const saveAdminMessage = useSaveAdminMessage()
  const name = useGetUser()

  function handleSaveButtonPressed() {
    if (newMessage && newMessage?.length > 0) {
      saveAdminMessage(tripId, newMessage, name)
      setTimeout(() => {
        mutate()
      }, 100)
      setNewMessage("")
    }
  }

  return (
    <TaxiAdminCard margin="0" padding="3">
      <Heading size="md" padding="6">
        Kommentarer
      </Heading>
      <Table>
        <Thead>
          <Tr>
            <Th>Melding</Th>
            <Th textAlign="right">Skrevet av</Th>
            <Th textAlign="right">Opprettet</Th>
          </Tr>
        </Thead>
        <Tbody>
          {messages?.map((message) => (
            <Tr key={message.createdAt}>
              <Td>{message.messageBody}</Td>
              <Td textAlign="right">{message.author}</Td>
              <Td fontFamily="Roboto Mono" textAlign="right">
                {formatDatetime(message.createdAt)}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Box padding="6" display="flex" flexDirection="row">
        <Input
          placeholder="Skriv ny kommentar"
          size="md"
          mr="6"
          value={newMessage}
          onChange={(event) => setNewMessage(event.target.value)}
        />
        <Button variant="primary" onClick={handleSaveButtonPressed}>
          Lagre
        </Button>
      </Box>
    </TaxiAdminCard>
  )
}

export default withErrorBoundry(TripAdminMessagesContainer)

function useSaveAdminMessage() {
  const post = usePostRequest()

  return useCallback(
    async (tripId: string, message: string, author: string) => {
      return post(dashboardApiUrl(`admin-messages/${tripId}`), {
        message: message,
        author: author,
      })
    },
    [post],
  )
}
