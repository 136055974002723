import {
  Badge,
  Box,
  Heading,
  InfoSelect,
  Item,
  ItemLabel,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@vygruppen/spor-react"
import React, { useCallback, useState } from "react"
import { dashboardApiUrl } from "src/api/base-url"
import {
  CompanyDetails,
  CompanyStatus,
  useGetCompanyStatuses,
} from "src/api/dashboard"
import { usePostRequest } from "src/api/useSuspenseFetch"
import { withErrorBoundry } from "src/api/withErrorBoundry"
import { UpdateCompanyMessageComponent } from "src/components/companyComponents/updateCompanyMessageComponent"
import { TaxiAdminCard } from "src/components/layout/TaxiAdminCard"
import { CompanyIdLink } from "src/components/links/TripIdLink"

function CompanyDetailsPage() {
  const { data: { companies = [] } = {}, mutate } = useGetCompanyStatuses()

  return (
    <TaxiAdminCard>
      <Heading as="h2">Selskapsstatus</Heading>
      {companies && (
        <Box flex="1" textAlign="left" mt="6">
          <CompanyDetailsPageTable
            companyStatuses={companies}
            mutate={mutate}
          />
        </Box>
      )}
    </TaxiAdminCard>
  )
}

type CompanyStatusTableProps = {
  companyStatuses: CompanyDetails[]
  mutate: () => void
}

function CompanyDetailsPageTable({
  companyStatuses,
  mutate,
}: CompanyStatusTableProps) {
  return (
    <Table size="md" variant="simple">
      <Thead>
        <Tr>
          <Th>Selskap</Th>
          <Th width={700}>Driftsmelding</Th>
          <Th></Th>
          <Th>Status</Th>
          <Th>Endre status</Th>
        </Tr>
      </Thead>
      <Tbody>
        {companyStatuses.map((item) => (
          <Tr key={item.companyId}>
            <Td>
              <CompanyIdLink
                companyId={item.companyId}
                companyName={item.companyName}
              />
            </Td>
            <Td>{item.message}</Td>
            <Td>
              <UpdateCompanyMessageComponent
                companyId={item.companyId}
                companyMessage={item.message}
              />
            </Td>
            <Td>
              <TripStatus status={item.companyStatus} />
            </Td>
            <Td>
              {item.companyStatus === CompanyStatus.TEST_COMPANY ? (
                "Kan ikke endre status på et testselskap"
              ) : (
                <EditTripStatus
                  currentStatus={item.companyStatus}
                  companyId={item.companyId}
                  mutate={mutate}
                ></EditTripStatus>
              )}
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}

export default withErrorBoundry(CompanyDetailsPage)

type EditTripStatusProps = {
  currentStatus: CompanyStatus
  companyId: string
  mutate: () => void
}

function EditTripStatus({
  currentStatus,
  companyId,
  mutate,
}: EditTripStatusProps) {
  const saveNewCompanyStatus = useSaveNewCompanyStatus()
  const [status, setStatus] = useState(currentStatus)

  function handleSaveButtonPressed(companyId: string, companyStatus: string) {
    setStatus(companyStatus as CompanyStatus)
    saveNewCompanyStatus(companyId, companyStatus as CompanyStatus)

    setTimeout(() => {
      mutate()
    }, 100)
  }

  return (
    <InfoSelect
      label=""
      onChange={(event) => handleSaveButtonPressed(companyId, event.toString())}
      items={[
        { key: CompanyStatus.OFFLINE },
        { key: CompanyStatus.TESTING },
        { key: CompanyStatus.LIVE },
        { key: CompanyStatus.TERMINATED },
      ]}
      placeholder={currentStatus}
      value={status}
    >
      {(item) => (
        <Item textValue={item.key}>
          <ItemLabel>{item.key}</ItemLabel>
        </Item>
      )}
    </InfoSelect>
  )
}

type TripStatusProps = {
  status: string
}

function TripStatus(tripStatusProps: TripStatusProps) {
  switch (tripStatusProps.status) {
    case "OFFLINE":
      return (
        <Badge borderRadius="6" px="2" variant="solid" colorScheme="grey">
          {tripStatusProps.status}
        </Badge>
      )
    case "TERMINATED":
      return (
        <Badge borderRadius="6" px="2" variant="solid" colorScheme="red">
          {tripStatusProps.status}
        </Badge>
      )
    case "LIVE":
      return (
        <Badge borderRadius="6" px="2" variant="solid" colorScheme="dark-green">
          {tripStatusProps.status}
        </Badge>
      )
    case "TESTING":
    case "TEST_COMPANY":
      return (
        <Badge borderRadius="6" px="2" variant="solid" colorScheme="yellow">
          {tripStatusProps.status}
        </Badge>
      )
    default:
      return (
        <Badge borderRadius="6" px="2" variant="solid">
          {tripStatusProps.status}
        </Badge>
      )
  }
}

function useSaveNewCompanyStatus() {
  const post = usePostRequest()

  return useCallback(
    async (companyId: string, companyStatus: CompanyStatus) => {
      return post(dashboardApiUrl(`company-status/${companyId}`), {
        companyId: companyId,
        companyStatus: companyStatus,
      })
    },
    [post],
  )
}
