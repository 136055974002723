import { ReceiptDataValidationResult } from "src/types/TripDataTypes"
import React from "react"
import { StaticAlert } from "@vygruppen/spor-react"

type Props = {
  failingReceiptDataValidationResults: ReceiptDataValidationResult[] | undefined
}

export function ReceiptDataAlerts({
  failingReceiptDataValidationResults,
}: Props) {
  if (!failingReceiptDataValidationResults) {
    return (
      <StaticAlert variant="error" mb="4" borderRadius="12">
        Mangler kvitteringsdata
      </StaticAlert>
    )
  } else if (failingReceiptDataValidationResults.length === 0) {
    return (
      <StaticAlert variant="success" mb="4" borderRadius="12">
        Gyldig kvitteringsdata
      </StaticAlert>
    )
  } else {
    return (
      <>
        {failingReceiptDataValidationResults.map((failingCheck) => {
          return (
            <StaticAlert
              variant="error"
              mb="4"
              borderRadius="12"
              key={failingCheck.description}
            >
              {failingCheck.description}
            </StaticAlert>
          )
        })}
      </>
    )
  }
}
