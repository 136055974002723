import { Link } from "react-router-dom"
import { Text } from "@chakra-ui/react"
import React from "react"

type Props = {
  tripId: string
  bookingNumber?: string
}

export function TripIdLink({ tripId, bookingNumber }: Props) {
  return (
    <Link to={`/trip/${tripId}`}>
      <Text color="blue.500">{bookingNumber || tripId}</Text>
    </Link>
  )
}

type CompanyIdProps = {
  companyName: string
  companyId: string
}

export function CompanyIdLink({ companyId, companyName }: CompanyIdProps) {
  return (
    <Link to={`/company/${companyId}`}>
      <Text color="blue.500">{companyName}</Text>
    </Link>
  )
}
