import React, { useState } from "react"
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react"
import { useAuth0 } from "src/auth/useAuth-compat"
import { Rating } from "src/types/CompanyDataTypes"
import { dashboardApiUrl } from "../../api/base-url"

type RatingTableProps = {
  ratings?: Rating[]
}

function RatingsTable({ ratings }: RatingTableProps) {
  const [isDeleteRatingModalVisible, setDeleteRatingModalVisible] =
    useState(false)
  const [ratingTripId, setRatingTripId] = useState<string>()

  const { getAccessTokenSilently } = useAuth0()

  async function deleteRating() {
    const accessToken = await getAccessTokenSilently()
    const url = dashboardApiUrl(`/rating/${ratingTripId}`)
    await fetch(url, {
      credentials: "include",
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    setDeleteRatingModalVisible(false)
  }

  function handleDeleteRating(tripId: string) {
    setDeleteRatingModalVisible(true)
    setRatingTripId(tripId)
  }

  if (ratings) {
    const ratingRows = ratings
      .sort((a, b) => b.createdAt.localeCompare(a.createdAt))
      .map((rating) => (
        <Tr key={rating.tripId}>
          <Td>{rating.score}</Td>
          <Td>{rating.tags?.join(", ")}</Td>
          <Td>{rating.createdAt}</Td>
          <Td>{rating.tripId}</Td>
          <Td>
            <Button
              colorScheme="red"
              variant="solid"
              onClick={() => handleDeleteRating(rating.tripId)}
            >
              Slett
            </Button>
          </Td>
        </Tr>
      ))

    return (
      <>
        <Accordion allowToggle marginTop="12">
          <AccordionItem>
            <Box backgroundColor="gray.100">
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Ratinger
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
            </Box>
            <AccordionPanel pb={4}>
              <Table size="sm" variant="striped">
                <Thead>
                  <Tr>
                    <Th>Score</Th>
                    <Th>Tags</Th>
                    <Th>Opprettet</Th>
                    <Th>TripId</Th>
                  </Tr>
                </Thead>
                <Tbody>{ratingRows}</Tbody>
              </Table>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        <Modal
          isOpen={isDeleteRatingModalVisible}
          onClose={() => setDeleteRatingModalVisible(false)}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Vil slette ratingen?</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text>
                {`Er du helt sikker på at du vil slette ratingen med tripId: ${ratingTripId} ? Vi mister da alle spor av denne utenom i slack og kan ikke sette den inn igjen på nytt.`}
              </Text>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={deleteRating}>
                Slett
              </Button>
              <Button
                variant="ghost"
                onClick={() => setDeleteRatingModalVisible(false)}
              >
                Lukk
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
  } else {
    return null
  }
}

export default RatingsTable
