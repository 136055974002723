import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react"
import {
  Input,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@vygruppen/spor-react"
import React, { useState } from "react"
import DatePicker from "react-datepicker"
import { useAuth0 } from "src/auth/useAuth-compat"
import { ReceiptData } from "src/types/TripDataTypes"
import { dashboardApiUrl } from "../../api/base-url"
import "react-datepicker/dist/react-datepicker.css"

type Props = {
  tripId: string
  receiptData?: ReceiptData
  isVisible: boolean
  hideModal: () => void
  requestedPickUpTime: string
}

function UpdateReceiptDataModal(props: Props) {
  const [tempCarId, setTempCarId] = useState(props?.receiptData?.carId)
  const [tempDistanceInMeter, setTempDistanceInMeter] = useState(
    props?.receiptData?.distanceInMeter,
  )
  const [tempFinalPriceInOre, setTempFinalPriceInOre] = useState(
    props?.receiptData?.finalPriceInOre,
  )
  const [startDate, setStartDate] = useState(
    props?.receiptData?.tripStart
      ? new Date(props?.receiptData?.tripStart)
      : new Date(props.requestedPickUpTime),
  )
  const [endDate, setEndDate] = useState(
    props?.receiptData?.tripStart
      ? new Date(props?.receiptData?.tripStop)
      : new Date(props.requestedPickUpTime),
  )

  const { getAccessTokenSilently } = useAuth0()

  async function updateReceiptData() {
    const accessToken = await getAccessTokenSilently()
    const url = dashboardApiUrl(`/receipt-data/${props.tripId}`)
    try {
      const response = await fetch(url, {
        credentials: "include",
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          tripId: props.tripId,
          carId: tempCarId,
          distanceInMeter: tempDistanceInMeter,
          finalPriceInOre: tempFinalPriceInOre,
          startDate: startDate,
          endDate: endDate,
        }),
      })
      const jsonResponse = await response.json()
      if (jsonResponse.updateSuccessfully) {
        alert("Kvitteringsinformasjon ble oppdatert")
      } else {
        alert("Klarte ikke å oppdatere kvitteringsdata. Sjekk aws-loggene")
      }
    } catch (error) {
      console.error(error)
    }
    props.hideModal()
  }

  return (
    <Modal isOpen={props.isVisible} onClose={props.hideModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Rediger kvitteringsdata</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>
            Er du helt sikker på at du vil redigere denne turen? Det er ikke
            mulig å angre.
          </Text>
          <Table size="sm">
            <Thead>
              <Tr>
                <Th>Kvitteringsdata</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>Distanse</Td>
                <Input
                  label="Distanse"
                  value={tempDistanceInMeter}
                  onChange={(event) =>
                    setTempDistanceInMeter(parseInt(event.target.value))
                  }
                />
              </Tr>
              <Tr>
                <Td>Sluttpris</Td>

                <Input
                  label="Øre"
                  value={tempFinalPriceInOre}
                  onChange={(event) =>
                    setTempFinalPriceInOre(parseInt(event.target.value))
                  }
                />
              </Tr>
              <Tr>
                <Td>Bil-id</Td>
                <Input
                  label="Bil-id"
                  value={tempCarId}
                  onChange={(event) => setTempCarId(event.target.value)}
                />
              </Tr>
              <Tr>
                <Td>Start</Td>
                <Td>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date as Date)}
                    showTimeSelect
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="yyyy-MM-dd hh:mm"
                  />
                </Td>
              </Tr>
              <Tr>
                <Td>Slutt</Td>
                <Td>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date as Date)}
                    showTimeSelect
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="yyyy-MM-dd hh:mm"
                  />
                </Td>
              </Tr>
              <Tr>
                <Td>Tilbudspris</Td>
                <Td>{props?.receiptData?.offerPriceInOre} øre</Td>
              </Tr>
              <Tr>
                <Td>Mva basispoeng</Td>
                <Td>{props?.receiptData?.taxBasisPoints}</Td>
              </Tr>
              <Tr>
                <Td>Organisasjonnummer</Td>
                <Td>{props?.receiptData?.transporterOrganizationNumber}</Td>
              </Tr>
            </Tbody>
          </Table>
        </ModalBody>

        <ModalFooter>
          <Button
            backgroundColor="#00866E"
            colorScheme="white"
            mr={3}
            onClick={updateReceiptData}
          >
            Lagre
          </Button>
          <Button variant="ghost" onClick={props.hideModal}>
            Lukk
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default UpdateReceiptDataModal
