import { Button } from "@chakra-ui/react"
import React, { ReactNode } from "react"
import { NavLink, useLocation } from "react-router-dom"

type Props = {
  to: string
  children: ReactNode
}
export function HeaderLinkButton({ to, children }: Props) {
  const history = useLocation()
  const isActiveLink = history.pathname === to

  return (
    <Button
      variant={isActiveLink ? "primary" : "tertiary"}
      size="lg"
      as={NavLink}
      to={to}
    >
      {children}
    </Button>
  )
}
