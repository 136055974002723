import { Box, Grid, GridItem } from "@chakra-ui/react"
import { useParams } from "react-router-dom"
import { useTrip } from "src/api/dashboard"
import { withErrorBoundry } from "src/api/withErrorBoundry"
import TripAdminMessagesContainer from "src/components/trip-details/TripAdminMessagesContainer"
import { TripDeveloperCard } from "src/components/trip-details/TripDeveloperCard"
import { TripInfoCard } from "src/components/trip-details/TripInfoCard"
import { TripOrderEventsCard } from "src/components/trip-details/TripOrderEventsCard"
import { TripPaymentLogCard } from "src/components/trip-details/TripPaymentLogCard"
import { TripReceiptDataCard } from "src/components/trip-details/TripReceiptDataCard"
import { TripStatusCard } from "src/components/trip-details/TripStatusCard"
import { TripStatusLogCard } from "src/components/trip-details/TripStatusLogCard"
import React from "react"

type TripPageParams = {
  tripId: string
}

export const TripPage = withErrorBoundry(() => {
  const { tripId } = useParams<TripPageParams>()
  if (!tripId) {
    return null
  }

  const trip = useTrip(tripId)
  if (!trip?.trip) {
    return null
  }

  return (
    <Box margin="12">
      <Grid templateColumns="repeat(2, 1fr)" gap="6">
        <GridItem rowSpan={3}>
          <TripInfoCard trip={trip.trip} companyName={trip.companyName ?? ""} />
        </GridItem>
        <GridItem rowSpan={1}>
          <TripStatusCard trip={trip.trip} />
        </GridItem>
        <GridItem rowSpan={6}>
          <TripReceiptDataCard
            tripId={tripId}
            requestedPickUpTime={trip.trip.requestedPickUpTime}
            receiptData={trip.receiptData}
          />
        </GridItem>
        <GridItem rowSpan={4}>
          <TripStatusLogCard tripId={tripId} />
        </GridItem>
        <GridItem rowSpan={3}>
          <TripAdminMessagesContainer tripId={tripId} />
        </GridItem>
        <GridItem rowSpan={3}>
          <TripPaymentLogCard tripData={trip} />
        </GridItem>
        <GridItem rowSpan={2}>
          <TripOrderEventsCard tripId={tripId} />
        </GridItem>
        <GridItem colSpan={2}>
          <TripDeveloperCard tripId={tripId} />
        </GridItem>
      </Grid>
    </Box>
  )
})
