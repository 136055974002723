import { useCallback } from "react"
import { dashboardApiUrl } from "src/api/base-url"
import { usePostRequest, useSuspenseFetch } from "src/api/useSuspenseFetch"
import { CompanyData } from "src/types/CompanyDataTypes"
import { ActiveTrips, TaskListType } from "src/types/DeveloperTypes"
import { OrderEvent, TaxiTrip, TripData } from "src/types/TripDataTypes"
import {
  LatestAdminMessageForTripIdResponse,
  AdminMessagesResponse,
  StatusItem,
} from "src/utils/taxi"

export function useOrderEvents(tripId: string) {
  const url = dashboardApiUrl(`/order/events/${tripId}`)
  return useSuspenseFetch<OrderEvent[]>(url).data
}

export function useTrip(tripId: string) {
  const url = dashboardApiUrl(`/trip/${tripId}`)
  return useSuspenseFetch<TripData>(url).data
}

export function useGetStatusLog(tripId: string) {
  const url = dashboardApiUrl(`/statuslog/${tripId}`)
  return useSuspenseFetch<StatusItem[]>(url).data
}

export function useGetAdminMessages(tripId: string) {
  const url = dashboardApiUrl(`/admin-messages/${tripId}`)
  return useSuspenseFetch<AdminMessagesResponse>(url)
}

export function useGetLatestAdminMessageForActiveErrorTrips() {
  const url = dashboardApiUrl("/admin-messages/error/recent")
  return useSuspenseFetch<LatestAdminMessageForTripIdResponse[]>(url).data
}

export function useTripTasks(tripId: string) {
  const url = dashboardApiUrl(`/tasks/${tripId}`)
  return useSuspenseFetch<TaskListType[]>(url).data
}

export function useActiveTrips() {
  const url = dashboardApiUrl("/trips/active")
  return useSuspenseFetch<ActiveTrips>(url).data
}

export function useSearchForTrips(
  userId?: string,
  vippsOrderId?: string,
  bookingNumber?: string,
  dinteroOrderId?: string,
) {
  const url = dashboardApiUrl("/trips/search")
  if (userId) {
    url.searchParams.append("userId", userId)
  } else if (vippsOrderId) {
    url.searchParams.append("vippsOrderId", vippsOrderId)
  } else if (bookingNumber) {
    url.searchParams.append("bookingNumber", bookingNumber)
  } else if (dinteroOrderId) {
    url.searchParams.append("dinteroOrderId", dinteroOrderId)
  }

  return useSuspenseFetch<{ trips: TaxiTrip[] }>(url).data?.trips
}

export function useUnfinishedTasks() {
  const url = dashboardApiUrl("/tasks/unfinished")
  return useSuspenseFetch<TaskListType[]>(url).data
}

export function useCompanyData(companyId: string) {
  const url = dashboardApiUrl(`/company/${companyId}`)
  return useSuspenseFetch<CompanyData>(url).data
}

export function useTripMessages(tripId: string) {
  const url = dashboardApiUrl(`/trip/${tripId}/messages`)
  return useSuspenseFetch<TripMessages>(url).data?.messages
}

export function useGetTripsForCompany(companyId: string) {
  const url = dashboardApiUrl(`/company-trips/${companyId}`)
  return useSuspenseFetch<{ trips: TaxiTrip[] }>(url).data?.trips
}

type TripMessages = {
  messages: TripMessage[]
}

export type TripMessage = {
  id: string
  direction: string
  type: string
  body: string
  createdAt: string
  vyOrderId: string
}

export type CompanyStatusResponse = {
  companies: CompanyDetails[]
}

export type CompanyDetails = {
  companyId: string
  companyName: string
  companyStatus: CompanyStatus
  message?: string
}

export enum CompanyStatus {
  TEST_COMPANY = "TEST_COMPANY",
  TESTING = "TESTING",
  LIVE = "LIVE",
  OFFLINE = "OFFLINE",
  TERMINATED = "TERMINATED",
}

export function useGetCompanyStatuses() {
  const url = dashboardApiUrl("/company-status")
  return useSuspenseFetch<CompanyStatusResponse>(url)
}

export function useCancelTaskRequest(taskId: string) {
  const post = usePostRequest()

  return useCallback(async () => {
    return post(dashboardApiUrl(`/tasks/${taskId}/cancel`), {})
  }, [post, taskId])
}

export function useRetryTaskRequest(taskId: string) {
  const post = usePostRequest()

  return useCallback(async () => {
    return post(dashboardApiUrl(`/tasks/${taskId}/retry`), {})
  }, [post, taskId])
}
