import { useParams } from "react-router-dom"
import { Container, Heading } from "@chakra-ui/react"
import RatingsTable from "src/components/companyComponents/RatingsTable"
import CompanyInformationTable from "src/components/companyComponents/CompanyInformationTable"
import CompanyStatistics from "src/components/companyComponents/CompanyStatistics"
import { useCompanyData } from "src/api/dashboard"
import { withErrorBoundry } from "src/api/withErrorBoundry"
import TripsForCompanyComponent from "src/components/TripsForCompanyComponent"
import { TaxiAdminCard } from "src/components/layout/TaxiAdminCard"
import React from "react"

type CompanyPageParams = {
  companyId: string
}

function CompanyPage() {
  const { companyId } = useParams<CompanyPageParams>()
  const company = useCompanyData(companyId)

  return (
    <TaxiAdminCard>
      <Heading>{company?.company.name}</Heading>
      <Container maxW="container.xl" pt="6">
        <CompanyStatistics companyStatistics={company?.companyStatistics} />
        <CompanyInformationTable taxiCompany={company?.company} />
        <TripsForCompanyComponent companyId={companyId} />
        <RatingsTable ratings={company?.ratingData} />
      </Container>
    </TaxiAdminCard>
  )
}

export default withErrorBoundry(CompanyPage)
