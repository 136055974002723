import { useAuth0 } from "src/auth/useAuth-compat"
import { Box, Button, Heading } from "@chakra-ui/react"
import VyTaxiAnimation from "src/components/VyTaxiAnimation"
import React from "react"

const LoggedOutPage = () => {
  const { loginWithRedirect } = useAuth0()

  return (
    <Box
      backgroundColor="brand.lightGreen20"
      padding="100"
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      height="100vh"
    >
      <Box
        padding="50"
        justifyContent="center"
        alignItems="center"
        display="flex"
        flexDirection="column"
      >
        <Heading color="brand.darkGrey">Taxi Admin</Heading>
        <Button variant="primary" marginTop="30" onClick={loginWithRedirect}>
          Logg inn
        </Button>
      </Box>
      <VyTaxiAnimation />
    </Box>
  )
}
export default LoggedOutPage
