import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react"
import { useGetTripsForCompany } from "src/api/dashboard"
import { TripIdLink } from "src/components/links/TripIdLink"
import { TripStatus } from "src/components/tripComponents/TripStatus"
import React from "react"

type FetchTripsForCompanyProps = {
  companyId: string
}

function TripsForCompanyComponent(props: FetchTripsForCompanyProps) {
  const trips = useGetTripsForCompany(props.companyId)
  return (
    <Accordion allowToggle marginTop="12">
      <AccordionItem>
        <Box backgroundColor="gray.100">
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                Siste 20 turer
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
        </Box>
        <AccordionPanel pb={4}>
          <Table size="sm" variant="striped">
            <Thead>
              <Tr>
                <Th>Forespurt hentetid</Th>
                <Th>TripId</Th>
                <Th>Selskap</Th>
                <Th>Status</Th>
              </Tr>
            </Thead>
            <Tbody>
              {trips?.map((item) => (
                <Tr key={item.tripId}>
                  <Td>{item.requestedPickUpTime}</Td>
                  <Td>
                    <TripIdLink tripId={item.tripId} />
                  </Td>
                  <Td>{item.companyName}</Td>
                  <Td>
                    <TripStatus
                      status={item.status}
                      errorCorrectionTripStatus=""
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}

export default TripsForCompanyComponent
