import { Grid, HStack } from "@chakra-ui/layout"
import { Button, Heading } from "@chakra-ui/react"
import { useState } from "react"
import { ReceiptDataAlerts } from "src/components/trip-details/ReceiptDataAlerts"
import { TripInfoStat } from "src/components/trip-details/TripInfoStat"
import UpdateReceiptDataModal from "src/components/tripComponents/UpdateReceipDataModal"
import {
  ReceiptData,
  receiptDataFieldDescriptions,
} from "src/types/TripDataTypes"
import { formatDatetime } from "src/utils/date"
import React from "react"
import { TaxiTripCard } from "src/components/layout/TaxiTripCard"

type Props = {
  tripId: string
  requestedPickUpTime: string
  receiptData: ReceiptData | undefined
}

export function TripReceiptDataCard({
  receiptData,
  tripId,
  requestedPickUpTime,
}: Props) {
  const [isVisible, setVisible] = useState(false)

  function hasError(field: string) {
    return !!receiptData?.failingReceiptDataValidationResults.find(
      (failingCheck) => failingCheck.field === field,
    )
  }

  return (
    <>
      <TaxiTripCard margin="0" padding="6">
        <Heading size="md" mb="4">
          Kvitteringsdata
        </Heading>
        <ReceiptDataAlerts
          failingReceiptDataValidationResults={
            receiptData?.failingReceiptDataValidationResults
          }
        />
        <Grid templateColumns="repeat(2, 1fr)" gap="4">
          <TripInfoStat
            stat={receiptDataFieldDescriptions.finalPriceInOre}
            hasError={hasError("finalPriceInOre")}
            value={
              receiptData ? getKroner(receiptData.finalPriceInOre) : "Mangler"
            }
          />
          <TripInfoStat
            stat={receiptDataFieldDescriptions.tripStart}
            hasError={hasError("tripStart")}
            value={
              receiptData ? formatDatetime(receiptData.tripStart) : "Mangler"
            }
          />
          <TripInfoStat
            stat={receiptDataFieldDescriptions.offerPriceInOre}
            hasError={hasError("offerPriceInOre")}
            value={
              receiptData ? getKroner(receiptData.offerPriceInOre) : "Mangler"
            }
          />
          <TripInfoStat
            stat={receiptDataFieldDescriptions.tripStop}
            hasError={hasError("tripStop")}
            value={
              receiptData ? formatDatetime(receiptData.tripStop) : "Mangler"
            }
          />
          <TripInfoStat
            stat={receiptDataFieldDescriptions.distanceInMeter}
            hasError={hasError("distanceInMeter")}
            value={
              receiptData ? receiptData.distanceInMeter + " meter" : "Mangler"
            }
          />
          <TripInfoStat
            stat={receiptDataFieldDescriptions.carId}
            hasError={hasError("carId")}
            value={receiptData ? receiptData.carId : "Mangler"}
          />
        </Grid>
        <HStack mt="4" justifyContent="flex-end">
          <Button variant="primary" onClick={() => setVisible(true)}>
            Endre verdier
          </Button>
        </HStack>
      </TaxiTripCard>
      <UpdateReceiptDataModal
        isVisible={isVisible}
        hideModal={() => setVisible(false)}
        requestedPickUpTime={requestedPickUpTime}
        tripId={tripId}
        receiptData={receiptData}
      />
    </>
  )
}

export function getKroner(ore: string | number) {
  return Number(ore) / 100 + " kr"
}
