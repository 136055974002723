import { Heading, Table, Tbody, Td, Th, Thead, Tr } from "@vygruppen/spor-react"
import React from "react"
import { TaxiAdminCard } from "src/components/layout/TaxiAdminCard"
import UpdateTaxiCompaniesInMetabaseButton from "src/components/metabase/UpdateTaxiCompaniesInMetabase"
import UpdateTaxiTripsInMetabaseButton from "src/components/metabase/UpdateTaxiTripsInMetabase"

function Metabase() {
  return (
    <TaxiAdminCard>
      <Heading as="h2">Metabase</Heading>
      <Table size="sm" variant="simple" colorScheme="grey">
        <Thead>
          <Tr>
            <Th>Handling</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>Oppdater oversikt over taxi-selskap i Metabase</Td>
            <Td>
              <UpdateTaxiCompaniesInMetabaseButton />
            </Td>
          </Tr>
          <Tr>
            <Td>Oppdater oversikt over taxi-trips i Metabase</Td>
            <Td>
              <UpdateTaxiTripsInMetabaseButton />
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </TaxiAdminCard>
  )
}

export default Metabase
