import React, { useCallback, useState } from "react"
import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Tab,
  TabList,
  Tabs,
} from "@vygruppen/spor-react"
import { useNavigate } from "react-router-dom"
import { TaxiAdminCard } from "../components/layout/TaxiAdminCard"

type SearchType =
  | "tripId"
  | "bookingNumber"
  | "vippsOrderId"
  | "userId"
  | "dinteroOrderId"

function HomePage() {
  const navigate = useNavigate()
  const [searchType, setSearchType] = useState<SearchType>("tripId")
  const [searchValue, setSearchValue] = useState("")

  const handleSubmit = useCallback(() => {
    if (searchType === "tripId") {
      navigate("/trip/" + searchValue)
    } else {
      navigate("/trips/search?" + searchType + "=" + searchValue)
    }
  }, [searchValue, searchType, navigate])

  const handleTabChange = (index: number) => {
    switch (index) {
      case 0:
        setSearchType("tripId")
        break
      case 1:
        setSearchType("bookingNumber")
        break
      case 2:
        setSearchType("vippsOrderId")
        break
      case 3:
        setSearchType("userId")
        break
      case 4:
        setSearchType("dinteroOrderId")
        break
      default:
        setSearchType("dinteroOrderId")
        break
    }
  }

  return (
    <TaxiAdminCard>
      <Heading as="h1" paddingBottom={6}>
        Finn ordre
      </Heading>
      <FindOrderTabs onChange={handleTabChange}></FindOrderTabs>
      <Box mt="3">
        <form onSubmit={handleSubmit}>
          <Flex gap={6}>
            <Input
              label=""
              mt="1"
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <Button mr="12" type="submit" variant="primary" width="30%">
              Finn tur
            </Button>
          </Flex>
        </form>
      </Box>
    </TaxiAdminCard>
  )
}

type FindOrderTabsProps = {
  onChange: (index: number) => void
}

function FindOrderTabs({ onChange }: FindOrderTabsProps) {
  return (
    <Tabs variant="base" onChange={onChange} isFitted>
      <TabList>
        <Tab>Trip-id</Tab>
        <Tab>Bookingnummer</Tab>
        <Tab>Vipps Ordre-id</Tab>
        <Tab>Kundenummer</Tab>
        <Tab>Dintero-id</Tab>
      </TabList>
    </Tabs>
  )
}

export default HomePage
