import { Box } from "@vygruppen/spor-react"
import React, { ReactNode } from "react"

type Props = {
  bg?: string
  margin?: string
  padding?: string
  children: ReactNode
  color: string
}

export function TaxiTripStatusCard({
  children,
  margin = "12",
  padding = "12",
  color,
}: Props) {
  return (
    <Box
      margin={margin}
      padding={padding}
      backgroundColor={color}
      borderRadius={12}
    >
      {children}
    </Box>
  )
}
