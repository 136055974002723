import { Grid, Heading } from "@chakra-ui/layout"
import React from "react"
import { TaxiAdminCard } from "src/components/layout/TaxiAdminCard"
import { TripInfoStat } from "src/components/trip-details/TripInfoStat"
import { getKroner } from "src/components/trip-details/TripReceiptDataCard"
import { TripData } from "src/types/TripDataTypes"

type Props = {
  tripData: TripData
}

export function TripPaymentLogCard({ tripData: { paymentData } }: Props) {
  return (
    <TaxiAdminCard margin="0" padding="6">
      <Heading size="md" mb="4">
        Betalingsdata
      </Heading>
      <Grid templateColumns="repeat(2, 1fr)" gap="4">
        <TripInfoStat
          stat="Reservert"
          value={
            paymentData
              ? getKroner(
                  paymentData.capturedAmount +
                    paymentData.remainingAmountToCapture,
                )
              : "Mangler"
          }
        />
        <TripInfoStat
          stat="Trukket"
          value={paymentData ? getKroner(paymentData.capturedAmount) : "0 kr"}
        />
        <TripInfoStat
          stat="Refundert"
          value={paymentData ? getKroner(paymentData.refundedAmount) : "0 kr"}
        />
      </Grid>
    </TaxiAdminCard>
  )
}
