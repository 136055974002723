import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@vygruppen/spor-react"
import React, { useCallback, useState } from "react"
import { dashboardApiUrl } from "src/api/base-url"
import { usePostRequest } from "src/api/useSuspenseFetch"

type Props = {
  companyId: string
  companyMessage?: string
}

export function UpdateCompanyMessageComponent(props: Props) {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [newMessage, setNewMessage] = useState("")
  const [warningModalIsOpen, setWarningModalIsOpen] = useState<boolean>(false)
  const updateCompanyStatus = useUpdateCompanyStatus()

  function handleUpdateStatusOnPress() {
    updateCompanyStatus(props.companyId, newMessage)
    setIsOpen(false)
    setWarningModalIsOpen(true)
  }

  return (
    <>
      <Button
        variant="tertiary"
        onClick={() => setIsOpen(true)}
        borderRadius={12}
      >
        Endre driftsmelding
      </Button>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Endre driftsmelding</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Dette vil oppdatere driftmeldingen til selskapet</Text>
            <Input
              label=""
              placeholder="Skriv ny melding"
              mt="6"
              value={newMessage}
              onChange={(e) => setNewMessage(e.currentTarget.value)}
            ></Input>
          </ModalBody>

          <ModalFooter>
            <Button
              backgroundColor="#00866E"
              color="white"
              mr={3}
              onClick={handleUpdateStatusOnPress}
            >
              Endre
            </Button>
            <Button
              variant="ghost"
              background="red"
              color="white"
              onClick={() => setIsOpen(false)}
            >
              Avbryt
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={warningModalIsOpen}
        onClose={() => setWarningModalIsOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Endret status på turen</ModalHeader>
          <ModalBody>
            <Text>Last inn siden på nytt og sjekk driftsmeldingen.</Text>
          </ModalBody>
          <ModalCloseButton />
          <ModalFooter>
            <Button
              variant="ghost"
              onClick={() => setWarningModalIsOpen(false)}
            >
              Lukk
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

function useUpdateCompanyStatus() {
  const post = usePostRequest()

  return useCallback(
    async (companyId: string, companyMessage?: string) => {
      return post(dashboardApiUrl(`/company-message/${companyId}`), {
        message: companyMessage,
        companyId: companyId,
      })
    },
    [post],
  )
}
