import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Grid,
  GridItem,
} from "@chakra-ui/react"
import { useTripMessages } from "src/api/dashboard"
import SyntaxHighlighter from "react-syntax-highlighter"
import { formatXml } from "src/utils/xmlformatter"
import React, { useState } from "react"

type Props = {
  tripId: string
}
export function TripMessagesTable({ tripId }: Props) {
  const messages = useTripMessages(tripId)

  if (!messages) {
    return null
  }

  return (
    <AccordionItem>
      <h2>
        <AccordionButton>
          <Box flex="1" textAlign="left">
            Meldinger fra teknologileverandør
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        {messages.map((item) => (
          <>
            <Grid templateColumns="repeat(3, 1fr)">
              <GridItem colSpan={1}>
                <DirectionEmoji value={item.direction} />
                {item.type}
              </GridItem>
              <GridItem colSpan={1}>{item.createdAt}</GridItem>
              <Message value={item.body} />
            </Grid>
          </>
        ))}
      </AccordionPanel>
    </AccordionItem>
  )
}

function Message({ value }: { value: string }) {
  const [isVisible, setIsVisible] = useState(false)

  return (
    <>
      <GridItem>
        <Button
          colorScheme="teal"
          size="xs"
          onClick={() => setIsVisible((val) => !val)}
          marginBottom="18"
        >
          Vis/skjul melding
        </Button>
      </GridItem>
      {isVisible && (
        <GridItem colSpan={3}>
          <SyntaxHighlighter language="xml">
            {formatXml(value)}
          </SyntaxHighlighter>
        </GridItem>
      )}
    </>
  )
}

function DirectionEmoji({ value }: { value: string }) {
  if (value === "INCOMING") {
    return <span title={value}>📦</span>
  } else if (value === "OUTGOING") {
    return <span title={value}>🥏</span>
  } else {
    return <span>{value}</span>
  }
}
