import { Route, Routes } from "react-router-dom"
import HomePage from "src/pages/HomePage"
import LoggedOutPage from "src/pages/LoggedOutPage"
import { TripPage } from "src/pages/TripPage"
import Metabase from "src/pages/Metabase"
import UnfinishedTasksPake from "src/pages/UnfinishedTasksPage"
import CompanyPage from "src/pages/CompanyPage"
import OngoingTrips from "src/pages/OngoingTrips"
import ErrorTrips from "src/pages/ErrorTrips"
import PreBookedTrips from "src/pages/PreBookedTrips"
import SearchPage from "src/pages/SearchPage"
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react"
import Header from "src/components/Header"
import CompanyStatusPage from "src/pages/CompanyStatusPage"
import React from "react"
import { Language, SporProvider } from "@vygruppen/spor-react"

const App = () => {
  return (
    <SporProvider language={Language.NorwegianBokmal}>
      <UnauthenticatedTemplate>
        <LoggedOutPage />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <Header />
        <Routes>
          <Route path="/trip/:tripId" element={<TripPage />} />
          <Route path="/company/:companyId" element={<CompanyPage />} />
          <Route path="/metabase" element={<Metabase />} />
          <Route path="/company-status" element={<CompanyStatusPage />} />
          <Route
            path="/developer/unfinished-tasks"
            element={<UnfinishedTasksPake />}
          />
          <Route path="/trips/ongoing" element={<OngoingTrips />} />
          <Route path="/trips/error" element={<ErrorTrips />} />
          <Route path="/trips/prebooked" element={<PreBookedTrips />} />
          <Route path="/trips/search" element={<SearchPage />} />
          <Route path="/" element={<HomePage />} />
        </Routes>
      </AuthenticatedTemplate>
    </SporProvider>
  )
}
export default App
