import { StaticCard } from "@vygruppen/spor-react"
import React, { ReactNode } from "react"

type Props = {
  bg?: string
  margin?: string
  padding?: string
  children: ReactNode
}

export function TaxiAdminCard({
  children,
  margin = "6",
  padding = "9",
}: Props) {
  return (
    <StaticCard margin={margin} padding={padding} colorScheme="white">
      {children}
    </StaticCard>
  )
}
