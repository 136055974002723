export type RefundTripsProps = {
  tripId: string
}

export type TripData = {
  trip?: Trip
  companyName?: string
  receiptData?: ReceiptData
  paymentData?: PaymentData
  bookingData?: BookingData
}

export type BookingData = {
  bookingToken: string
}

export type PaymentData = {
  capturedAmount: number
  refundedAmount: number
  remainingAmountToCapture: number
  remainingAmountToRefund: number
}

export type ReceiptData = {
  tripId: string
  priceExcludingTaxInOre: number
  taxBasisPoints: number
  taxAmountinOre: number
  finalPriceInOre: number
  currency: string
  transporterOrganizationNumber: string
  offerPriceInOre: number
  tripStart: string
  tripStop: string
  createdAt: string
  distanceInMeter: number
  carId: string
  receiptDataComplete: boolean
  failingReceiptDataValidationResults: ReceiptDataValidationResult[]
}

export type ReceiptDataField = keyof ReceiptData
export const receiptDataFieldDescriptions: Record<ReceiptDataField, string> = {
  // Only the fields that are in current use have non-blank descriptions
  createdAt: "",
  currency: "",
  failingReceiptDataValidationResults: "",
  priceExcludingTaxInOre: "",
  receiptDataComplete: "",
  taxAmountinOre: "",
  taxBasisPoints: "",
  transporterOrganizationNumber: "",
  tripId: "",
  finalPriceInOre: "Sluttpris",
  offerPriceInOre: "Makspris",
  tripStart: "Starttid",
  tripStop: "Sluttid",
  distanceInMeter: "Distanse",
  carId: "Løyvenummer",
}

export type ReceiptDataValidationResult = {
  field: string
  description: string
}

export type Trip = {
  status: string
  technologyProvider: string
  tripId: string
  userId: string
  vippsOrderId: string
  bookingNumber: string
  carId?: string
  companyId: string
  createdAt: string
  dinteroOrderId: string
  finalPriceInOre: number
  offerId: string
  offerPriceInOre: number
  requestedPickUpTime: string
  ignoreStatusUpdatesFromTechnologyProvider: boolean
  route: Route
}

export type Route = {
  from: Address
  to: Address
}

export type Address = {
  label: string
  country: string
  district: string
  street: string
  houseNumber: string
  postalCode: string
  latitude: number
  longitude: number
}

export type ReceiptDataUpdatedMetadata = {
  existingValues: Record<string, string>
  newValues: Record<string, string>
}

export type OrderEvent = {
  id: string
  tripId: string
  event: string
  createdAt: string
  metadata: ReceiptDataUpdatedMetadata
}

export type TaxiTrip = {
  tripId: string
  requestedPickUpTime: string
  status: string
  companyName: string
}
