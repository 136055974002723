import React from "react"
import App from "src/App"
import "src/baseStyles.css"
import { ChakraProvider } from "@chakra-ui/react"
import vyColorScheme from "src/vyColorScheme"
import { HashRouter as Router } from "react-router-dom"
import { PublicClientApplication } from "@azure/msal-browser"
import { MsalProvider } from "@azure/msal-react"
import { msalConfig } from "src/auth/config"
import { createRoot } from "react-dom/client"

const msalInstance = new PublicClientApplication(msalConfig)
const appContainer = document.getElementById("root")

if (appContainer) {
  const root = createRoot(appContainer)

  root.render(
    <React.StrictMode>
      <Router>
        <ChakraProvider theme={vyColorScheme}>
          <MsalProvider instance={msalInstance}>
            <App />
          </MsalProvider>
        </ChakraProvider>
      </Router>
    </React.StrictMode>,
  )
} else {
  handleRootNotInDOM()
}

function handleRootNotInDOM() {
  const rootNotExistingError =
    "Det oppstod en feil som ikke er forventet. Vennligst kontakt en utvikler for hjelp."
  alert(rootNotExistingError)
  console.error(rootNotExistingError)
}
