import { Badge } from "@vygruppen/spor-react"
import { getTripStatusText } from "src/utils/trip-status"
import React from "react"

type Props = {
  status: string
  errorCorrectionTripStatus: string
}

export function TripStatus(props: Props) {
  if (props.errorCorrectionTripStatus) {
    return (
      <Badge borderRadius="6" px="2" colorScheme="solid">
        {getTripStatusText(props.errorCorrectionTripStatus)}
      </Badge>
    )
  }
  switch (props.status) {
    case "CREATED":
    case "UNASSIGNED":
    case "ASSIGNED":
    case "ON_ITS_WAY":
    case "ARRIVED":
    case "PASSENGER_ON_BOARD":
      return (
        <Badge borderRadius="6" px="2" colorScheme="grey">
          {getTripStatusText(props.status)}
        </Badge>
      )
    case "FINISHED":
      return (
        <Badge
          borderRadius="6"
          px="2"
          variant="solid"
          colorScheme="light-green"
        >
          {getTripStatusText(props.status)}
        </Badge>
      )
    case "CANCELED":
    case "CANCELED_BY_OPERATOR":
    case "CANCELED_BY_PROVIDER":
    case "CANCELED_BY_USER":
    case "CANCELED_BY_SYSTEM":
    case "CANCELED_PAID_IN_CAR":
    case "CANCELED_BY_UNKNOWN":
      return (
        <Badge borderRadius="6" px="2" colorScheme="red">
          {getTripStatusText(props.status)}
        </Badge>
      )
    case "NO_SHOW":
      return (
        <Badge borderRadius="6" px="2" colorScheme="orange">
          {getTripStatusText(props.status)}
        </Badge>
      )
    case "UNKNOWN":
    default:
      return (
        <Badge borderRadius="6" px="2" colorScheme="grey">
          {props.status}
        </Badge>
      )
  }
}
