import { Heading, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import { useOrderEvents } from "src/api/dashboard"
import { TaxiAdminCard } from "src/components/layout/TaxiAdminCard"
import { formatDatetime } from "src/utils/date"
import React, { Fragment } from "react"
import {
  OrderEvent,
  ReceiptDataField,
  receiptDataFieldDescriptions,
  ReceiptDataUpdatedMetadata,
} from "../../types/TripDataTypes"

type Props = {
  tripId: string
}

export function TripOrderEventsCard({ tripId }: Props) {
  const orderEvents = useOrderEvents(tripId)

  return (
    <TaxiAdminCard margin="0" padding="6">
      <Heading size="md" padding="6">
        Ordrehendelser
      </Heading>
      <Table>
        <Thead>
          <Tr>
            <Th>Beskrivelse</Th>
            <Th textAlign="right">Opprettet</Th>
          </Tr>
        </Thead>
        <Tbody>
          {orderEvents?.map((log) => {
            return (
              <Fragment key={log.createdAt}>
                <Tr>
                  {renderLog(log)}
                  <Td fontFamily="Roboto Mono" textAlign="right">
                    {formatDatetime(log.createdAt)}
                  </Td>
                </Tr>
              </Fragment>
            )
          })}
        </Tbody>
      </Table>
    </TaxiAdminCard>
  )
}

function renderLog(log: OrderEvent) {
  return log.metadata !== null ? (
    <Td>
      <details>
        <summary>{getOrderEventText(log.event)}</summary>
        {generateDifferenceReport(log.metadata).map((line, index) => (
          <p key={index}>{line}</p>
        ))}
      </details>
    </Td>
  ) : (
    <Td>{log.event}</Td>
  )
}

function generateDifferenceReport(logMetadata: ReceiptDataUpdatedMetadata) {
  const changedValues: React.JSX.Element[] = Object.keys(
    logMetadata.existingValues,
  )
    .filter(
      (key) =>
        Object.prototype.hasOwnProperty.call(logMetadata.newValues, key) &&
        logMetadata.existingValues[key] !== logMetadata.newValues[key],
    )
    .map((key) => {
      const keyLabel = receiptDataFieldDescriptions[key as ReceiptDataField]
      const oldValue =
        logMetadata.existingValues[key] === "" ? (
          '""'
        ) : (
          <b>{logMetadata.existingValues[key]}</b>
        )
      const newValue =
        logMetadata.newValues[key] === "" ? (
          '""'
        ) : (
          <b>{logMetadata.newValues[key]}</b>
        )
      return (
        <p key={key}>
          <b>{keyLabel}</b> ble endret fra <b>{oldValue}</b> til{" "}
          <b>{newValue}</b>
        </p>
      )
    })

  return changedValues
}

function getOrderEventText(event: string) {
  switch (event) {
    case "PAYMENT_CAPTURED":
      return "Trukket betaling"
    case "NO_SHOW_PAYMENT_CAPTURED":
      return "Trukket betaling for manglende oppmøte"
    case "PAYMENT_CANCELED":
      return "Kansellert betaling"
    case "PAYMENT_REFUNDED":
      return "Refundert betaling"
    case "TRIP_SENT_TO_DWH":
      return "Sendt tur til datavarehuset"
    case "RECEIPT_SENT":
      return "Sendt kvittering til kunde"
    case "NO_SHOW_RECEIPT_SENT":
      return "Sendt kvittering for manglende oppmøte"
    case "NO_SHOW_TRIP_PAYMENT_CANCELED":
      return "Kansellert betaling grunnet manglende oppmøte"
    case "SENT_TO_DINTERO":
      return "Sendt noe til dintero"
    case "REFUND_RECEIPT_SENT_EVENT":
      return "Sendt refusjonskvittering til kunde"
    case "NO_SHOW_REFUND_RECEIPT_SENT_EVENT":
      return "Sendt refusjonskvittering for manglende oppmøte"
    case "ERROR_TRIP_DISCOVERED":
      return "Systemet har markert turen som en feiltur"
    case "FINALPRICE_HIGHER_THAN_MAXPRICE":
      return "Sluttprisen var høyere enn makspris"
    case "SET_STATUS_AND_IGNORE_UPDATES":
      return "Oppdatert status og ignorerer nye statuser fra tilbyder"
    case "CANCEL_BECAUSE_OF_UNASSIGNED_TOO_LONG":
      return "Turen skal kanselleres automatisk da taxien ikke har dukket opp til avtalt tid"
    case "CANCEL_BECAUSE_OF_NORGES_TAXI":
      return "Turen skal kanselleres automatisk da det er en feiltur fra Norges Taxi"
    case "RECEIPT_DATA_UPDATED":
      return "Kvitteringsdata endret manuelt"
    case "UNKNOWN":
    default:
      return event
  }
}
