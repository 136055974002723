import {
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  OrderedList,
  Select,
  Text,
} from "@chakra-ui/react"
import React, { useCallback, useState } from "react"
import { dashboardApiUrl } from "src/api/base-url"
import { usePostRequest } from "src/api/useSuspenseFetch"
import { Button } from "@vygruppen/spor-react"
import { EditOutline30Icon } from "@vygruppen/spor-icon-react"

type Props = {
  tripId: string
  color: string
  bg: string
}

export function UpdateTripStatusComponent(props: Props) {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [newStatus, setNewStatus] = useState("")
  const [warningModalIsOpen, setWarningModalIsOpen] = useState<boolean>(false)
  const updateTripStatus = useUpdateTripStatus()

  function handleUpdateStatusOnPress() {
    updateTripStatus(props.tripId, newStatus)
    setIsOpen(false)
    setWarningModalIsOpen(true)
  }

  return (
    <>
      <Button
        variant="primary"
        onClick={() => setIsOpen(true)}
        leftIcon={<EditOutline30Icon />}
      >
        Endre status
      </Button>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Endre status</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Dette vil:
              <OrderedList>
                <ListItem>Kansellere alle pågående tasks for turen</ListItem>
                <ListItem>
                  <strong>Ignorere alle nye statuser for turen</strong>
                </ListItem>
                <ListItem>Sette ny status på turen</ListItem>
                <ListItem>Håndtere den satte statusen</ListItem>
              </OrderedList>
            </Text>
            <Select
              placeholder="Velg ny status"
              mt="6"
              value={newStatus}
              onChange={(e) => setNewStatus(e.currentTarget.value)}
            >
              <option value="FINISHED">FINISHED</option>
              <option value="NO_SHOW">NO_SHOW</option>
              <option value="CANCELED_BY_OPERATOR">CANCELED_BY_OPERATOR</option>
            </Select>
          </ModalBody>

          <ModalFooter>
            <Button
              backgroundColor="#00866E"
              colorScheme={props.color}
              mr={3}
              onClick={handleUpdateStatusOnPress}
            >
              Endre
            </Button>
            <Button variant="ghost" onClick={() => setIsOpen(false)}>
              Avbryt
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={warningModalIsOpen}
        onClose={() => setWarningModalIsOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Endret status på turen</ModalHeader>
          <ModalBody>
            <Text>Last inn siden på nytt og sjekk statusloggen.</Text>
          </ModalBody>
          <ModalCloseButton />
          <ModalFooter>
            <Button
              variant="ghost"
              onClick={() => setWarningModalIsOpen(false)}
            >
              Lukk
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

function useUpdateTripStatus() {
  const post = usePostRequest()

  return useCallback(
    async (tripId: string, newTripStatus: string) => {
      return post(dashboardApiUrl(`/trip/${tripId}/status`), {
        newStatus: newTripStatus,
      })
    },
    [post],
  )
}
