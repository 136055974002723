export function getHostName() {
  return window.location.hostname
}

export function isLocalhost() {
  const host = getHostName()
  return host?.includes("localhost") || host?.includes("127.0.0.1")
}

export function isStageEnvironment() {
  const host = getHostName()
  return host?.includes("stage")
}

export function isProductionEnvironment() {
  return !isLocalhost() && !isStageEnvironment()
}
