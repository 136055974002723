import { Heading, HStack } from "@chakra-ui/layout"
import { UpdateTripStatusComponent } from "src/components/tripComponents/UpdateTripStatusComponent"
import { Trip } from "src/types/TripDataTypes"
import { getTripStatusText, isTripCancelled } from "src/utils/trip-status"
import React from "react"
import { Text } from "@vygruppen/spor-react"
import { TaxiTripStatusCard } from "src/components/layout/TaxiTripStatusCard"

type Props = {
  trip: Trip
}

export function TripStatusCard({ trip }: Props) {
  const { bg, color } = getColorsForStatus(trip.status)

  return (
    <TaxiTripStatusCard bg={bg} padding="6" margin="0" color={bg}>
      <Heading size="sm" color={color}>
        Status
      </Heading>
      <Heading mt="2" size="lg" color={color}>
        {getTripStatusText(trip.status)}
      </Heading>
      {trip.ignoreStatusUpdatesFromTechnologyProvider && (
        <Heading mt="2" size="xs" color={color}>
          Fremtidige statusoppdateringer fra teknologileverandør blir ignorert
        </Heading>
      )}
      <HStack mt="8">
        {!isTripCancelled(trip.status) ? (
          <UpdateTripStatusComponent
            tripId={trip.tripId}
            color={color}
            bg={bg}
          />
        ) : (
          <Text color={color}>Kan ikke oppdatere status på ordre</Text>
        )}
      </HStack>
    </TaxiTripStatusCard>
  )
}

function getColorsForStatus(status: string): { bg: string; color: string } {
  switch (status) {
    case "CREATED":
    case "UNASSIGNED":
    case "ASSIGNED":
    case "ON_ITS_WAY":
    case "ARRIVED":
    case "PASSENGER_ON_BOARD":
      return {
        bg: "#383E42",
        color: "white",
      }
    case "FINISHED":
      return {
        bg: "#00ab8c",
        color: "white",
      }
    case "CANCELED":
    case "CANCELED_BY_OPERATOR":
    case "CANCELED_BY_PROVIDER":
    case "CANCELED_BY_USER":
    case "CANCELED_BY_SYSTEM":
    case "CANCELED_PAID_IN_CAR":
    case "CANCELED_BY_UNKNOWN":
      return {
        bg: "red",
        color: "white",
      }
    case "NO_SHOW":
      return {
        bg: "orange",
        color: "white",
      }
    case "UNKNOWN":
    default:
      return {
        bg: "#383E42",
        color: "white",
      }
  }
}
