import { Heading } from "@chakra-ui/layout"
import { Accordion } from "@chakra-ui/react"
import { TaxiAdminCard } from "src/components/layout/TaxiAdminCard"
import TripTasksTable from "src/components/tripComponents/TripTasksTable"
import { TripMessagesTable } from "src/components/TripMessagesTable"
import React from "react"

type Props = {
  tripId: string
}

export function TripDeveloperCard({ tripId }: Props) {
  return (
    <TaxiAdminCard margin="0" padding="6">
      <Heading size="md" padding="6">
        Utviklerinfo
      </Heading>
      <Accordion allowToggle allowMultiple paddingBottom="8">
        <TripTasksTable tripId={tripId} />
        <TripMessagesTable tripId={tripId} />
      </Accordion>
    </TaxiAdminCard>
  )
}
