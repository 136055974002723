import { Heading, Table, Tr, Th, Td, Tbody, Thead } from "@chakra-ui/react"
import { useGetStatusLog } from "src/api/dashboard"
import { TaxiAdminCard } from "src/components/layout/TaxiAdminCard"
import { formatDatetime } from "src/utils/date"
import { getTripStatusText } from "src/utils/trip-status"
import React from "react"

type Props = {
  tripId: string
}

export function TripStatusLogCard({ tripId }: Props) {
  const statuses = useGetStatusLog(tripId)
  return (
    <TaxiAdminCard margin="0" padding="6">
      <Heading size="md" padding="6">
        Statuslogg
      </Heading>
      <Table>
        <Thead>
          <Tr>
            <Th>Beskrivelse</Th>
            <Th textAlign="right">Opprettet</Th>
          </Tr>
        </Thead>
        <Tbody>
          {statuses?.map((log) => (
            <Tr key={log.createdAt}>
              <Td>
                <div>
                  <text style={{ marginRight: 10 }}>
                    {returnEmojiForStatus(log.status)}
                  </text>
                  <text>{getTripStatusText(log.status)}</text>
                </div>
              </Td>
              <Td fontFamily="Roboto Mono" textAlign="right">
                {formatDatetime(log.createdAt)}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TaxiAdminCard>
  )
}

function returnEmojiForStatus(status: string): string {
  if (status === "UNASSIGNED") {
    return "⌛️"
  } else if (status === "ASSIGNED") {
    return "👌"
  } else if (status === "ON_ITS_WAY") {
    return "🚕 "
  } else if (status === "ARRIVED") {
    return "🎯"
  } else if (status === "PASSENGER_ON_BOARD") {
    return "📦"
  } else if (status === "FINISHED") {
    return "✅"
  } else if (status === "NO_SHOW") {
    return "🥲"
  } else return "❌"
}
