import { Trip } from "src/types/TripDataTypes"
import { formatDatetime } from "src/utils/date"
import React from "react"
import { TaxiTripCard } from "../layout/TaxiTripCard"
import {
  TextLink,
  Heading,
  Table,
  Tbody,
  Td,
  Th,
  Tr,
} from "@vygruppen/spor-react"
import { dinteroOrderBaseURL } from "src/utils/environmentVariables"

type Props = {
  trip: Trip
  companyName: string
}

export function TripInfoCard({ trip, companyName }: Props) {
  return (
    <TaxiTripCard margin="0" padding="6">
      <Heading as="h2" padding="3">
        Turdata
      </Heading>
      <Table>
        <Tbody>
          <Tr>
            <Th>Selskap</Th>
            <Td>
              <TextLink href={`/company/${trip.companyId}`}>
                {companyName}
              </TextLink>
            </Td>
          </Tr>
          <Tr>
            <Th>Teknologileverandør</Th>
            <Td>{trip.technologyProvider}</Td>
          </Tr>
          <Tr>
            <Th>Kundenummer</Th>
            <Td>{trip.userId}</Td>
          </Tr>
          <Tr>
            <Th>Bookingnr</Th>
            <Td>{trip.bookingNumber}</Td>
          </Tr>
          <Tr>
            <Th>Dintero Id</Th>
            <Td>
              <TextLink
                isExternal
                href={dinteroOrderBaseURL() + trip.dinteroOrderId}
              >
                {trip.dinteroOrderId}
              </TextLink>
            </Td>
          </Tr>
          <Tr>
            <Th>VippsOrdreId</Th>
            <Td>{trip.vippsOrderId}</Td>
          </Tr>
          <Tr>
            <Th>TripId</Th>
            <Td>{trip.tripId}</Td>
          </Tr>
          <Tr>
            <Th>Opprettet</Th>
            <Td>{formatDatetime(trip.createdAt)}</Td>
          </Tr>
          <Tr>
            <Th>Forespurt hentetid</Th>
            <Td>{formatDatetime(trip.requestedPickUpTime)}</Td>
          </Tr>
        </Tbody>
      </Table>
    </TaxiTripCard>
  )
}
