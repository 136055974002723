import { DeleteIcon, RepeatClockIcon } from "@chakra-ui/icons"
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react"
import {
  useCancelTaskRequest,
  useRetryTaskRequest,
  useTripTasks,
} from "src/api/dashboard"
import { withErrorBoundry } from "src/api/withErrorBoundry"
import React from "react"

type TripTasksTableProps = {
  tripId: string
}

function TripTasksTable(props: TripTasksTableProps) {
  const taskList = useTripTasks(props.tripId)

  return (
    <AccordionItem>
      <h2>
        <AccordionButton>
          <Box flex="1" textAlign="left">
            Ordretasks
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        <Table size="sm" variant="striped">
          <Thead>
            <Tr>
              <Th>TaskId</Th>
              <Th>Navn</Th>
              <Th>Status</Th>
              <Th>Retries</Th>
              <Th>Opprettet:</Th>
              <Th>Sist oppdatert:</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {taskList &&
              taskList.map((item) => (
                <Tr key={item.id}>
                  <Td>{item.id}</Td>
                  <Td>{item.taskName}</Td>
                  <Td>{item.taskStatus}</Td>
                  <Td>{item.retries}</Td>
                  <Td>{item.createdAt}</Td>
                  <Td>{item.lastUpdatedAt}</Td>
                  <Td>
                    {item.taskStatus === "STARTED" && (
                      <>
                        <RetryTaskButton taskId={item.id} />
                        <CancelTaskButton taskId={item.id} />
                      </>
                    )}
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </AccordionPanel>
    </AccordionItem>
  )
}

export function CancelTaskButton({ taskId }: { taskId: string }) {
  const cancelTask = useCancelTaskRequest(taskId)

  function handleCancel() {
    if (window.confirm("Er du sikker?")) {
      cancelTask()
    }
  }

  return (
    <Button
      leftIcon={<DeleteIcon />}
      colorScheme="teal"
      variant="solid"
      onClick={handleCancel}
      size="xs"
    >
      Kanseller
    </Button>
  )
}

export function RetryTaskButton({ taskId }: { taskId: string }) {
  const retryTask = useRetryTaskRequest(taskId)

  function handleRetryTask() {
    if (window.confirm("Er du sikker?")) {
      retryTask()
    }
  }

  return (
    <Button
      leftIcon={<RepeatClockIcon />}
      colorScheme="teal"
      variant="solid"
      onClick={handleRetryTask}
      size="xs"
      mr="2"
    >
      Kjør på nytt
    </Button>
  )
}

export default withErrorBoundry(TripTasksTable)
