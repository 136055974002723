import { isProductionEnvironment } from "./environments"

export function taxiBaseURL() {
  if (isProductionEnvironment()) {
    return "https://www.vy.no/services/taxi/dashboard/"
  } else {
    return (
      process.env.REACT_APP_TAXI_BASE_URL ||
      "https://stage.vy.no/services/taxi/dashboard/"
    )
  }
}

export function dinteroOrderBaseURL() {
  return isProductionEnvironment()
    ? "https://vy.backoffice.dintero.com/P11112562/orders/orders/"
    : "https://vy.backoffice.test.dintero.com/P11223464/orders/orders/"
}
