import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import { TaxiCompany } from "src/types/CompanyDataTypes"
import React from "react"

type CompanyInformationTableProps = {
  taxiCompany?: TaxiCompany
}

function CompanyInformationTable(props: CompanyInformationTableProps) {
  if (props.taxiCompany) {
    const {
      name,
      technologyProvider,
      id,
      organizationNumber,
      phoneNumber,
      featureFlagKey,
      companyStatus,
    } = props.taxiCompany

    return (
      <Table size="sm" variant="striped">
        <Thead>
          <Tr>
            <Th>Selskapsinformasjon</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>Navn</Td>
            <Td>{name}</Td>
          </Tr>
          <Tr>
            <Td>Teknologileverandør</Td>
            <Td>{technologyProvider}</Td>
          </Tr>
          <Tr>
            <Td>Id</Td>
            <Td>{id}</Td>
          </Tr>
          <Tr>
            <Td>Organisasjonsnummer</Td>
            <Td>{organizationNumber}</Td>
          </Tr>
          <Tr>
            <Td>Telefonnummer</Td>
            <Td>{phoneNumber}</Td>
          </Tr>
          <Tr>
            <Td>LaunchDarkly-flagg</Td>
            <Td>{featureFlagKey}</Td>
          </Tr>
          <Tr>
            <Td>Status</Td>
            <Td>{companyStatus}</Td>
          </Tr>
        </Tbody>
      </Table>
    )
  } else {
    return null
  }
}

export default CompanyInformationTable
