export function getTripStatusText(status: string) {
  switch (status) {
    case "CREATED":
      return "Opprettet"
    case "UNASSIGNED":
      return "Venter på tildeling"
    case "ASSIGNED":
      return "Tildelt bil"
    case "ON_ITS_WAY":
      return "Bil på vei"
    case "ARRIVED":
      return "Ankommet hentested"
    case "PASSENGER_ON_BOARD":
      return "Passasjer ombord"
    case "FINISHED":
      return "Fullført"
    case "CANCELED":
      return "Kansellert"
    case "CANCELED_BY_OPERATOR":
      return "Kansellert av Vy"
    case "CANCELED_BY_PROVIDER":
      return "Kansellert av selskap"
    case "CANCELED_BY_USER":
      return "Kansellert av kunde"
    case "CANCELED_BY_SYSTEM":
      return "Kansellert automatisk"
    case "CANCELED_VIA_RECEIPT_CORRECTION":
      return "Kansellert av selskap gjennom taxi-driver"
    case "CANCELED_PAID_IN_CAR":
      return "Kansellert, betalt i bil"
    case "CANCELED_BY_UNKNOWN":
      return "Kansellert, Ukjent"
    case "NO_SHOW":
      return "Ingen oppmøte"
    case "WAITING_FOR_RECEIPT_CORRECTION_FROM_DRIVER":
      return "Venter på data fra sjåfør"
    case "HAS_RECEIVED_RECEIPT_CORRECTION_FROM_DRIVER":
      return "Mottatt data fra sjåfør"
    case "UNKNOWN":
    default:
      return status
  }
}

export function isTripCancelled(status: string) {
  switch (status) {
    case "CANCELED":
    case "CANCELED_BY_OPERATOR":
    case "CANCELED_BY_PROVIDER":
    case "CANCELED_BY_USER":
    case "CANCELED_BY_SYSTEM":
    case "CANCELED_PAID_IN_CAR":
    case "CANCELED_BY_UNKNOWN":
      return true
    default:
      return false
  }
}
