import {
  Box,
  Heading,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@vygruppen/spor-react"
import { TripIdLink } from "src/components/links/TripIdLink"
import { useUnfinishedTasks } from "src/api/dashboard"
import { withErrorBoundry } from "src/api/withErrorBoundry"
import { TaskListType } from "src/types/DeveloperTypes"
import { TaxiAdminCard } from "src/components/layout/TaxiAdminCard"
import {
  CancelTaskButton,
  RetryTaskButton,
} from "src/components/tripComponents/TripTasksTable"
import React from "react"

function UnfinishedTasksPage() {
  const taskList = useUnfinishedTasks()

  return (
    <TaxiAdminCard>
      <Heading as="h2">Aktive tasks i backend: ({taskList?.length})</Heading>
      {taskList && (
        <Box flex="1" textAlign="left" mt="6">
          <UnfinishedTasksTable taskList={taskList} />
        </Box>
      )}
    </TaxiAdminCard>
  )
}

type UnfinishedTasksTableProps = {
  taskList: TaskListType[]
}

function UnfinishedTasksTable({ taskList }: UnfinishedTasksTableProps) {
  return (
    <Table size="sm" variant="simple">
      <Thead>
        <Tr>
          <Th>TaskId</Th>
          <Th>TripId</Th>
          <Th>Navn</Th>
          <Th>Status</Th>
          <Th>Retries</Th>
          <Th>Opprettet:</Th>
          <Th>Sist oppdatert:</Th>
          <Th></Th>
        </Tr>
      </Thead>
      <Tbody>
        {taskList &&
          taskList.map((item) => (
            <Tr key={item.id}>
              <Td>{item.id}</Td>
              <Td>
                <TripIdLink tripId={item.tripId} />
              </Td>
              <Td>{item.taskName}</Td>
              <Td>{item.taskStatus}</Td>
              <Td>{item.retries}</Td>
              <Td>{item.createdAt}</Td>
              <Td>{item.lastUpdatedAt}</Td>
              <Td>
                {item.taskStatus === "STARTED" && (
                  <>
                    <RetryTaskButton taskId={item.id} />
                    <CancelTaskButton taskId={item.id} />
                  </>
                )}
              </Td>
            </Tr>
          ))}
      </Tbody>
    </Table>
  )
}

export default withErrorBoundry(UnfinishedTasksPage)
