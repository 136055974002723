import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useAuth0 } from "src/auth/useAuth-compat"
import { dashboardApiUrl } from "../api/base-url"
import { InfoSelect, Item, ItemLabel } from "@vygruppen/spor-react"

type CompanyListItem = {
  id: string
  name: string
}

function CompaniesDropdownMenu() {
  const { getAccessTokenSilently } = useAuth0()
  const navigate = useNavigate()
  const [menuListItems, setMenuListItem] = useState<CompanyListItem[]>()

  useEffect(() => {
    const getCompanyList = async () => {
      async function getCompaniesList(): Promise<CompanyListItem[]> {
        const accessToken = await getAccessTokenSilently()
        const completeUrl = dashboardApiUrl("/companies")

        const response = await fetch(completeUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })

        return await response.json()
      }
      const companiesList = await getCompaniesList()
      setMenuListItem(
        companiesList.sort((a, b) => a.name.localeCompare(b.name)),
      )
    }
    getCompanyList()
  }, [getAccessTokenSilently])

  return (
    <InfoSelect
      label="Velg selskap"
      placeholder="Velg selskap"
      onChange={(event) => navigate(`/company/${event}`)}
      items={menuListItems || []}
    >
      {(item) => (
        <Item key={item.id} textValue={item.name}>
          <ItemLabel>{item.name}</ItemLabel>
        </Item>
      )}
    </InfoSelect>
  )
}

export default CompaniesDropdownMenu
