import { Button } from "@chakra-ui/react"
import React from "react"
import { useAuth0 } from "src/auth/useAuth-compat"
import { dashboardApiUrl } from "../../api/base-url"

function UpdateTaxiTripsInMetabaseButton() {
  const { getAccessTokenSilently } = useAuth0()

  async function updateTrips() {
    const accessToken = await getAccessTokenSilently()
    const url = dashboardApiUrl(`/resend-trips-metabase`)
    try {
      const response = await fetch(url, {
        credentials: "include",
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      })
      const jsonResponse = await response.json()
      if (jsonResponse.updateSuccessfully) {
        alert("Alle taxiturene ble oppdatert")
      } else {
        alert("Klarte ikke å oppdatere taxiturene.")
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Button variant="secondary" onClick={updateTrips}>
      Oppdater
    </Button>
  )
}

export default UpdateTaxiTripsInMetabaseButton
