import React, { useEffect } from "react"
import Lottie from "lottie-web"
import { Box } from "@chakra-ui/react"
import animation from "src/assets/taxiloader.json"

function VyTaxiAnimation() {
  useEffect(() => {
    createLottie()
  }, [])
  function createLottie() {
    const svgContainer = document.getElementById("svgContainer")
    if (svgContainer == null) return

    Lottie.loadAnimation({
      container: svgContainer, // the dom element that will contain the animation
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: animation, // the path to the animation json
    })
  }
  return <Box id="svgContainer" />
}

export default VyTaxiAnimation
