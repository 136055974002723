import { extendTheme } from "@chakra-ui/react"

const vyColorScheme = extendTheme({
  styles: {
    global: {
      "html, body": {
        background: "background.accent",
      },
    },
  },
  colors: {
    background: {
      accent: "#EBEBEC",
      white: "#ffffff",
    },
  },
  components: {
    Link: {
      baseStyle: {
        color: "#037D67",
        textDecoration: "underline",
      },
    },
    Th: {
      baseStyle: {
        textTransform: "none",
      },
    },
    Badge: {
      baseStyle: {
        textTransform: "none",
      },
    },
    Button: {
      variants: {
        menu: {
          color: "white",
          bg: "#606568",
        },
        primary: {
          color: "white",
          bg: "#00866E",
        },
      },
    },
  },
})

export default vyColorScheme
