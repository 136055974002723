import React from "react"

const VyLogo = () => {
  return (
    <div>
      <svg width={100} height={100} viewBox="0 0 957.7 484.7">
        <g id="Layer_1">
          <path
            fill="#222720"
            d="M722.5,301.8c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.1-0.3-0.3-0.4h0c-7.7-1.1-14.7-3.6-21-7.6 c-14.1-9-25.2-26.2-34.6-54.8c-1.2-3.8-48.3-163.8-51.3-174.2c-10.7-36.5-30.9-52.8-49.5-59.7c-9.6-3.5-18.7-4.6-26-4.6 c-4.1,0-13,0-13,0c-0.2,0-0.4,0.2-0.4,0.4c0,0.2,0.1,0.3,0.3,0.4v0c5.2,0.7,10.8,2.1,16.4,4.5c17.3,7.3,35.3,23.7,45.7,59 c2.3,7.9,49.7,169.1,50.7,172.3c9.6,29.9,20.9,47.7,35.3,56.9c9.2,5.8,19.6,8.3,31.7,8.3H722.5z"
          />
          <path
            fill="#222720"
            d="M764.1,301.8c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.1-0.3-0.3-0.4l0,0c-21.1-2.7-34.8-32.4-42.3-57.6 c-7.9-26.5-36.5-123.2-53.5-179.1C651.3,8.2,598.1,0.1,574,0.1l0,0c-0.2,0-0.4,0.2-0.4,0.4c0,0.2,0.1,0.3,0.3,0.4l0,0 c22.5,3,54.4,16.6,68.2,63.5c12.1,41.2,50.5,171.9,52,176.3c9.2,27.3,20.1,43.8,33.7,52.7c9.2,6,19.7,8.5,32,8.5H764.1z"
          />
          <path
            fill="#222720"
            d="M210.3,484.7c-2.7,0-5.1,0-6.5,0c-19.2,0-42.3-9.8-55-54.6L34.3,39.2C27.5,15.7,17.4,4,0.3,1l0,0 C0.1,1,0,0.9,0,0.7c0-0.2,0.2-0.4,0.4-0.4h0.2h17c22.9,0,35.4,11.5,43.3,38.9c0,0,105.9,359.4,115.5,393.3 c7.1,25.1,15.4,42.2,28.3,49.1c2.1,1.1,3.8,1.8,5.6,2.3h0c0.2,0,0.3,0.2,0.3,0.4C210.7,484.5,210.5,484.7,210.3,484.7z"
          />
          <g>
            <path
              fill="#222720"
              d="M171.5,53.8C160.6,15.9,143.4,0,111.7,0H96.3c-0.2,0-0.4,0.2-0.4,0.4c0,0.2,0.1,0.3,0.3,0.4l0,0 c24.7,3.5,39.1,19.8,48.7,53c0,0,94.8,322.8,107.1,365c2.7-9.1,7.3-24.6,13-44C253.7,336,171.5,53.8,171.5,53.8z"
            />
          </g>
          <path
            fill="#222720"
            d="M219.2,484.7h11.3c14.1,0,24.2-3.7,31.8-9.9c12.3-10,17.9-26.6,23.1-44C286.1,428.2,386.4,88,390,74.7 c11.9-44.9,38.7-64.5,67.2-71.4c4.7-1.2,9.5-2,14.3-2.5l0,0c0.2,0,0.3-0.2,0.3-0.4c0-0.2-0.2-0.4-0.4-0.4h-12.5 c-37.6,0-79.5,14.9-95.4,74.6c-5.7,21.5-103.9,353.5-104.6,356.1c-5.2,17.4-11.1,35.8-23.4,45.8c-5,4.1-9.6,6.3-16.2,7.3h0 c-0.2,0-0.3,0.2-0.3,0.4C218.8,484.5,219,484.7,219.2,484.7L219.2,484.7z"
          />
          <path
            fill="#222720"
            d="M734.5,250.5l59-196.7c9.6-33.2,24-49.5,48.7-53h0c0.2,0,0.3-0.2,0.3-0.4c0-0.2-0.2-0.4-0.4-0.4h-15.4 c-31.6,0-48.8,15.8-59.8,53.7l-45.6,153.3C721.3,207.1,733.4,247.3,734.5,250.5z"
          />
          <path
            fill="#0A9578"
            d="M889.8,0.3h-16.4c-26.9,0-41.5,13.5-50.9,45.7c0,0-58.4,195.2-58.9,196.9c-4.3,13.8-9.7,27.2-15.1,35.8 c5.1,7,10.6,11.4,15.9,12.4c10.1-8.7,18.6-24.8,26.1-49.3c0.4-1.3,58.6-195.8,58.6-195.8c8.1-27.9,20.1-41.8,40.7-45h0 c0.2,0,0.3-0.2,0.3-0.4C890.2,0.5,890,0.3,889.8,0.3z"
          />
          <path
            fill="#222720"
            d="M770.2,311.6h-26.4c-12,39.6-27.9,92.1-33.8,111.8c-16.4,55.1,9.5,60.7,19.4,60.7c1.3,0,13.3,0,20.9,0h0 c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.1-0.3-0.3-0.4h0c-11.3-2.7-26.7-14.4-13.1-60C737.2,423.5,761.2,342.3,770.2,311.6z"
          />
          <path
            fill="#222720"
            d="M924.7,0.7c0.1,0,0.3-0.2,0.3-0.4c0-0.2-0.2-0.4-0.4-0.4v0l-3.7,0c-22.9,0-35.4,11.5-43.3,38.9 c0,0-45.1,151.9-59.3,198.9c-14.2,47.1-23.9,59.7-43.2,63l-1,0.2c-0.2,0-0.3,0.2-0.3,0.4c0,0.2,0.2,0.4,0.4,0.4h13.1 c33.6,0,46.1-22.4,57.9-61.8c9.9-33,67.8-226.5,67.8-226.5C914.6,8,919.3,2.4,924.7,0.7L924.7,0.7z"
          />
          <g>
            <path
              fill="#222720"
              d="M796.8,311c-9.3,31.6-35.7,121.6-35.7,121.6c-14,46.8,8.1,51.6,16.5,51.6H798l0,0c0.2,0,0.4-0.2,0.4-0.4 c0-0.2-0.1-0.3-0.3-0.4l0,0c-9.6-2.5-22.1-12.7-10.7-50.9c0,0,29-98.2,40-135.7C821.1,303.3,810.4,309.2,796.8,311z"
            />
          </g>
          <path
            fill="#222720"
            d="M846.6,484.2c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.1-0.3-0.3-0.4h0c-8.1-2.3-18.2-11.3-8.7-43.2 c0,0,112-381.2,118.3-402.8s-8.8-35.1-22.4-37.1c-0.1,0-0.5-0.1-0.6,0.2c-0.1,0.3,0.3,0.5,0.4,0.5c2.7,1.4,5.7,4.7,4.1,10.3 s-126,429-126,429c-11.9,39.8,6.9,43.9,14,43.9H846.6z"
          />
          <path
            fill="#222720"
            d="M669.4,301.8c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.1-0.3-0.3-0.4h0c-7.6-1.1-14.5-3.5-20.8-7.5 c-14.8-9.4-26.3-27.6-36-58.6c-0.8-2.7-48.5-165-50.2-170.6C552.5,30,536.3,13.6,520.3,6c-9.7-4.6-19.3-5.9-27.5-5.9 c-5.3,0-10.1,0-14,0h-0.1c-0.2,0-0.4,0.2-0.4,0.4c0,0.2,0.1,0.3,0.3,0.4l0,0c2.6,0.4,39,1.5,57.3,63.5s40.3,136.2,47.7,162.3 c16,56.6,36.7,75.1,70,75.1H669.4z"
          />
          <path
            fill="#222720"
            d="M500.8,21.4C500.8,21.4,500.8,21.4,500.8,21.4c-13.4,11.5-24.5,28.6-31.1,53.3C469,77.3,369.6,415.6,367.4,423 c-8.4,28.2-15.4,56.2-47,61v0c-0.2,0-0.3,0.2-0.3,0.4c0,0.2,0.2,0.4,0.4,0.4h1.6h4.7c0,0,4.5-0.1,5.4-0.1 c32.1-1.3,48.2-15,61.9-61.6c10-34.1,101.5-345.6,102.3-348.3c3.9-13,8.6-27.9,15.3-40.9C510.3,31.6,505.4,25.2,500.8,21.4z"
          />
          <g>
            <path
              fill="#0A9578"
              d="M485.9,12.7c-10.2-2.9-24.6-2.1-37.7,8.4c-13.1,10.5-24.8,28.3-30.8,50.9c-0.7,2.6-104.6,356.2-105.4,358.8 c-5.2,17.4-10.8,33.9-23.1,44c-5.6,4.6-12.6,7.8-21.5,9.2l0,0c-0.2,0-0.3,0.2-0.3,0.4c0,0.2,0.2,0.4,0.4,0.4h16.3 c14.1,0,24.2-3.7,31.8-9.9c12.3-10,17.9-26.6,23.1-44c0.8-2.6,103.9-353.5,104.6-356.1c6.6-24.7,17.7-41.8,31-53.3 c3.9-3.3,7.9-6.2,12.1-8.6C486.2,12.8,486.1,12.8,485.9,12.7z"
            />
          </g>
          <path
            fill="#222720"
            d="M239.8,454.4c-4.6-6.7-8.5-14.7-11.2-24.3C224.7,416.1,116.1,46,116.1,46c-9.3-32.2-24-45.7-50.9-45.7H48.8 c-0.2,0-0.4,0.2-0.4,0.4c0,0.2,0.1,0.3,0.3,0.4c20.6,3.2,32.6,17,40.7,45L202,430.1c5.8,20.5,13.8,33.7,22.6,42 C230.9,468.4,236.2,462.6,239.8,454.4z"
          />
        </g>
      </svg>
    </div>
  )
}
export default VyLogo
